@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');


.smallGameCard {
    /* For the image, which will be included inline: */
    background-size: 100% 100%; /* This will stretch the image to the size.*/
    /* background-size: contain; */
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #f5f5f5;

    /* Type 1, more border */
    /* border: 15px solid;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px; */

    /* Type 2, less border */
    margin: 0px;
    /* margin-left: 0px;
    margin-right: 0px;
    margin-top: px;
    margin-bottom: 3px; */
    border: 15px solid;
}


@media print {
    .smallGameCard {
        page-break-inside: avoid;
        width: calc(100vw-80px);
        /* height: 90px; */
        /* When we print borderless, it loses much of the margin */
        border-left: 25px solid;
        border-right: 25px solid;
    }
    .noprint {
        display: none;
        visibility: hidden;
    }
    .characterSheet, .characterSheet > .page {
        margin: 0px;
        padding: 0px;
    }
    .characterSheet > .page > .column1, .characterSheet > .page > .column2 {
        width: 50%;
    }
     }
 @media not print {
    .characterSheet .letter {
        /*weird number, not sure why*/
        width: 819px; 
    }
    .characterSheet .tabloid {
        width: 1600px;
    }
    .smallGameCard {
        width: 790px;
        /* height: 90px; */
    }
    .characterSheet > .page > .column1, .characterSheet > .page > .column2 {
        max-width: 820px;
    }
    .smallGameCard {
        display: inline-block;
        /* margin: 5px; */
        margin: inherit;
        vertical-align: top;
    }  
    .smallGameCardContents > .bottom > .directions {
        /* I'm not sure why we need this on screen. In print there's no problem. */
        margin-bottom: -1px;
    }
    }


/* For attackActions */
.smallGameCard.attackAction {
    border-color: #450000; /* darker than the header color because this will be over an image, whereas that will not*/
}
.smallGameCard.attackAction .smallGameCardContents > .bottom .cost,
.smallGameCard.attackAction .smallGameCardContents > .bottom > .directions,
.smallGameCard.attackAction .header {
    background-color: #450000;
    color: white; /*white font on a darker background*/
}

/* For green/generative actions */
.smallGameCard.enhanceAction {
    border-color: #004500;
}
.smallGameCard.enhanceAction .smallGameCardContents > .bottom .cost,
.smallGameCard.enhanceAction .smallGameCardContents > .bottom > .directions,
.smallGameCard.enhanceAction .header {
    background-color: #004500;
    color: white; /*white font on a darker background*/
}

.smallGameCard.skillAction {
    border-color: #e6e6e6;
}
.smallGameCard.skillAction .smallGameCardContents > .bottom .cost,
.smallGameCard.skillAction .smallGameCardContents > .bottom > .directions,
.smallGameCard.skillAction .header {
    /* background-color: #ffffffcc; */
    background-color: #e6e6e6;
    color: black; /*black font on a lighter background*/
}
.smallGameCard.skillAction.description {
    background-color: #e6e6e6;
    color: black; /*black font on a lighter background*/
}

.smallGameCard .headerRowLeftAligned {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.smallGameCard .headerRowLeftAligned .header {
    font-family: 'Lexend', sans-serif;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 8px;
    padding-right: 40px;
    margin-bottom: 0px;
    flex: 0 1 auto;
    /* The width varies, which means the following clip-path doesn't do everything we want, but it's okay. */
    clip-path: polygon(0 0, 100% 0, calc(80%) 100%, 0 100%);
    /* Dynamic calculation to make the angle always be the same: */
    -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 40px) 100%, 0 100%);
    text-align: left;
}
.smallGameCard .headerRowLeftAligned .headerEmptyColumn {
    flex: 1;
}

.smallGameCard .headerRowLeftAligned .headerEmptyColumnForAreaEffect {
    flex: 1;
    padding: 10px 10px;
}

.smallGameCard .headerRowLeftAligned .headerEmptyColumn1 {
    flex: 1;
}

.smallGameCard .headerRowLeftAligned .headerEmptyColumn2_1_wide {
    padding: 10px 10px;
    width: 70px;
}
.smallGameCard .headerRowLeftAligned .headerEmptyColumn2_2_wide {
    padding: 10px 10px;
    width: 140px;
}
.smallGameCard .headerRowLeftAligned .headerEmptyColumn2_3_wide {
    padding: 10px 10px;
    width: 210px;
}

.stackedContent {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.smallGameCard .header .line1 {
    font-weight: bold;
    font-size: xx-large;
    display:block;
}
.smallGameCard .header .line2 {
    font-weight: normal;
    font-size: medium;
    display:block;
}
.smallGameCard .header .line3 {
    font-weight: normal;
    font-size: small;
    display:block;
}


.smallGameCardContents {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* height: 100%; */
    /* Set the height to the max possible without going past the edge of the div: */
    height: calc(100% - 81px);
    justify-content: space-between;
}
.smallGameCardContents > .top {
    padding: 10px;
    align-self: flex-start;
}
.smallGameCardContents > .bottom {
    font-family: 'Lexend', sans-serif;

    display: inline-block;
    width: 100%;
    align-self: flex-end;
    align-content: flex-end;
}
.smallGameCardContents > .bottom .costRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
.smallGameCardContents > .bottom .cost {
    font-size: large;
    font-weight: normal;
    padding: 10px;
    padding-right: 10px;
    padding-left: 40px;
    
    text-align: right;
    flex: 0 1 auto;
    clip-path: polygon(30px 0%, 100% 0, 100% 100%, 0% 100%);    
}
.smallGameCardContents > .bottom .costEmptyColumn {
    flex: 1;
}
.smallGameCardContents > .bottom > .directions {
    font-size: 20px;
    font-weight: normal;
    padding: 10px;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 10px;
}

.smallGameCardContents > .bottom > .directions > .commandColorText {
    font-family: 'Calibri', sans-serif;
    font-style: italic;
    font-weight: lighter;
    color: rgba(255, 255, 255, .8);
}

.characterSheet {
    display: grid;
    width: 100%;
}

.characterSheet > .page {
    page-break-before: always;
}
.characterSheet .characterHeader {
    padding: 20px;
    /* background-color: black; */
    /* color: white; */
}
.characterSheet .subHeader {
    padding: 10px;
    margin-top: 20px;
    font-size: large;
}
.characterSheet .characterHeader.attack, .characterSheet .subHeader.attack {
    background-color: #450000;
    color: white;
}
.characterSheet .characterHeader.defend, .characterSheet .subHeader.defend {
    background-color: #004500;
    color: white;
}
.characterSheet .characterHeader.upgrades, .characterSheet .subHeader.upgrades {
    background-color: #000045;
    color: white;
}

.characterSheet .characterHeader > .characterName {
    padding-right: 80px;
    font-size: xx-large;
    display: inline;
    vertical-align: top;
}
.characterSheet .characterHeader > .characterAbout {
    padding-right: 20px;
    font-size: medium;
    display: inline-block;
}
.characterSheet .characterHeader > .pageType {
    /* TODO Fill the whole right side, could be half the page */
    text-align: right;
    display: inline-block;
}
.characterSheet .characterHeader > .pageType.attack {
    /* background-color: #450000; */
    color: white;
    font-style: italic;
    font-size: xx-large;
    overflow: hidden;
    min-width: 20%;
}
.characterSheet .characterHeader > .pageType.defend {
    /* background-color: #004500; */
    color: white;
    font-style: italic;
    font-size: xx-large;
    overflow: hidden;
    min-width: 20%;
}
.characterSheet .characterHeader > .pageType.upgrades {
    /* background-color: #000045; */
    color: white;
    font-style: italic;
    font-size: xx-large;
    overflow: hidden;
    min-width: 20%;
}

.inventoryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: top;
    padding: 10px;
}
.inventoryRow .column20pct {
    width: 20%;
}
.inventoryRow .column30pct {
    width: 30%;
}
.inventoryRow .column40pct {
    width: 40%;
}
.inventoryRow .column50pct {
    width: 50%;
}