/* @import '~antd/dist/antd.css'; */

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.OverrideAntBg {
  background-color: white; 
  color: white;
  padding: 0 0 0 0;
}

.App-header {
  background-color: white; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.Advice {
  /* border-width: 10px solid #eee;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    transform: scaleY(0); */
  margin: 16px;
  color: black;
  overflow: auto;
  height: 100%;
  width:calc(100% - 20px);
}

.HEADER_ABOVE_TABS .ant-layout {
  height: calc(100vh - 10px);
}
.HEADER_IN_TABS .ant-layout {
  height: calc(100vh);
}

.HEADER_ABOVE_TABS .ant-tabs {
  height: calc(100vh - 50px);
}
.HEADER_IN_TABS .ant-tabs {
  height: calc(100vh);
}

.HEADER_ABOVE_TABS .ant-tabs-content-holder {
  height: calc(100vh - 55px);
}
.HEADER_IN_TABS .ant-tabs-content-holder {
  height: calc(100vh);
}
.HEADER_ABOVE_TABS .ant-tabs-content {
  height: calc(100vh - 95px);
}
.HEADER_IN_TABS .ant-tabs-content {
  height: calc(100vh - 40px);
}

/*********
Allotment's defaults in styles.css are wrong, they have 100% but that would be 100% of the whole window intsead of the remaining space.
**********/
.HEADER_ABOVE_TABS #JustAroundAllotment {
  height:calc(100vh - 55px);
  width:100%;
}
.HEADER_IN_TABS #JustAroundAllotment {
  height:calc(100vh);
  width:100%;
}
/* This one might not be required, TBD and remove if possible */
.HEADER_ABOVE_TABS #JustAroundAllotment > .allotment-module_splitView__L-yRc {
  height: calc(100vh - 55px);
}

.ant-tabs-tabpane > .Advice {
  /* Note: the 100% above doesn't work when it's inside a tab pane, ant is doing something different there */
  /* The following calculation is not quite right. 97vh - 50 can leave a bit of extra space, or at very small heights, start cutting off.*/
  height: calc(100vh - 60px);
}
.ant-tabs-extra-content {
  display:contents;
}
.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.site-tree-search-value {
  color: #f50;
}
[data-theme="dark"] .site-tree-search-value {
  color: #d84a1b;
}

.HEADER_ABOVE_TABS .chatBotResponse {
  padding: 10px;
  background-color: white;
  max-height:calc(90vh - 130px);
  overflow-y:scroll;
}
.HEADER_IN_TABS .chatBotResponse {
  padding: 10px;
  background-color: white;
  max-height:calc(90vh - 70px);
  overflow-y:scroll;
}

.HEADER_ABOVE_TABS .rdw2-editor-main {
  height: calc(100vh - 125px);
}
.HEADER_IN_TABS .rdw2-editor-main {
  height: calc(100vh - 80px);
}


.chatBotResponse blockquote {
  border-left: 10px solid #f1f1f1;
  padding-left: 10px;
  /* font-style: italic; */
}

/*Allotment's info:*/
:root {
  --focus-border: #007fd4;
  --sash-size: 20px;
  --sash-hover-size: 4px;
  --separator-border: #838383;
}


/************
CSS for this table:
*/
.battleTable {
  border-collapse: collapse;
  border: 1px solid lightgrey;
  padding: 10px;
}

.battleTable th {
  font-weight: bold;
  border: 1px solid white;
  padding: 10px;
  background-color: #f2f2f2;
}

.battleTable td {
  border: 1px solid rgb(255, 255, 255);
  padding: 10px;
}

.battleTable tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.battleTable tr:nth-child(odd) td {
  background-color: #ffffff;
}

.battleTable .ant-input-number-input {
  padding: 0px;
  height: 20px;
}

.battleTable .battleNumInputNarrow {
  width: 50px;
}
.battleTable .battleNumInputWide {
  width: 60px;
}




/********************
Styles for Algolia
*********************/
.ais-SearchBox-input, .searchInputBox {
  width: calc(100% - 60px);
  /* min-width: 400px; */
}


.ais-Menu .ais-Menu-item {
  /*********
  CSS to make these li show up on the same line:
  **********/
  display: inline-block;
  list-style-type: none;
  margin: 5px;
  padding: 2px;
  padding-left: 5px;
}
.ais-Menu .ais-Menu-item .ais-Menu-count {
  padding: 5px;
  color: gray;
  font-size: smaller;
}
.ais-Menu-item--selected {
  /******
  Highlight with an outline
  ********/
  outline: 2px solid gray;
  color: black;
}
.ais-Menu-item--selected .ais-Menu-label {
  color: black;
}



.RelatedNotes {
  height: 100%;
  overflow-y: scroll;
}

.chat-item-title-ref {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
  margin-right: 10px;
  font-size: smaller;
  background-color: #f5f5f5;
}
.chat-item-title-ref2 {
  display: inline-block;
  align-items: center;
  padding: 3px 0;
  padding-left: 30px;
  margin-left: 15px;
  gap: 8px;
  margin-right: 10px;
  font-size: smaller;
  background-color: #f5f5f5;
}
.chat-item-title-ref2 .chat-item-title-ref-button {
  font-size: small;
  background-color: #f5f5f5;
  border-bottom: 1px dashed blue;
  border-radius: 0px;
  margin-left: 15px;
  padding: 0px;
  margin-bottom:0px;
}

.chat-item-title-ref .chat-item-title-ref-span {
  /* text-align: center; */
  display: inline-flex;
  /* align-items: center; */
  /* justify-content: center; */
  min-height: 24px; /* Allow content to grow if there's many items*/
  /* padding: 8px; */
  font-size: smaller;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background-color: #f5f5f5;
}

.related-notes-large-panel {
  margin: 10px;
}
.extensionComponentInsteadOfTable {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}